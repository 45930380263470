#logo_full__house {
	animation: house-anim 300ms linear 1 normal forwards;
}

#logo_full__title {
	animation: title-anim 300ms linear 1 normal forwards;
	animation-timing-function: cubic-bezier(0, 0, 0.58, 1);
	animation-delay: 200ms;
	opacity: 0;
}

@keyframes house-anim {
	0% {
		transform: translate(100px, 20px);
	}
	100% {
		transform: translate(30px, 20px);
	}
}

@keyframes title-anim {
	0% {
		opacity: 0;
		transform: translate(-20px, 20px);
	}
	100% {
		opacity: 1;
		transform: translate(30px, 20px);
	}
}

@keyframes download-status {
	0% {
		transform: translateX(18px);
	}
	100% {
		transform: translateX(-20px);
	}
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected,
.ant-menu-item:active,
.ant-menu-submenu-title:active {
	background: #fff;
}

.left-menu .ant-menu:not(.ant-menu-inline-collapsed) {
	.ant-menu-item,
	.ant-menu-submenu-title {
		display: flex;
		align-items: center;
		padding-left: 24px;
	}
}

.left-menu {
	overflow: hidden;
	height: 100vh;
	left: 0;
	box-shadow: 0 20px 25px -5px rgb(0 0 0 / 15%), 0 10px 10px -5px rgb(0 0 0 / 4%);

	&.ant-layout-sider {
		position: fixed;
		z-index: 999;
	}

	&--faded {
		box-shadow: inset 0 -10px 8px -8px rgb(0 0 0 / 8%) !important;
	}

	.ant-menu {
		background: transparent;
	}

	&__tag.ant-tag {
		margin: 0;
		font-weight: 600;
		border-radius: 0;
		border-width: 0;
		border-bottom-left-radius: 6px;
		border-bottom-right-radius: 6px;
		color: #fff;

		&--mobile {
			border-radius: 6px;
		}
	}

	&-mobile {
		.ant-menu-item-selected .ant-menu-title-content a {
			color: #1c4775;
		}

		.ant-menu-item-active .ant-menu-item-icon {
			color: #1c4775;
		}

		.ant-menu-title-content,
		.ant-menu-title-content a {
			font-weight: 600;
			color: #6a737a;
		}
	}

	.ant-btn-link:focus {
		color: #1c4775;
	}

	.ant-menu.ant-menu-inline-collapsed > .ant-menu-submenu.left-menu__login > .ant-menu-submenu-title {
		padding: 0 calc(50% - 16px);
	}

	&__profile {
		text-align: left;

		span {
			color: #c1c4c8;
			font-size: 12px;
		}

		&__name {
			height: 16px;
			font-weight: 600;
			color: #6a737a;
		}
	}

	&__arrow {
		color: #1c4775;
	}

	.ant-layout-sider-trigger {
		position: relative;
	}

	.ant-menu-item-selected .ant-menu-title-content a {
		color: #1c4775;
	}

	.ant-menu-item-active .ant-menu-item-icon {
		color: #1c4775;
	}

	.ant-menu-title-content,
	.ant-menu-title-content a {
		font-weight: 600;
		color: #6a737a;
	}

	&--main {
		overflow-y: auto;
		overflow-x: hidden;
		margin-bottom: 12px;
	}

	&__column {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		height: 100%;

		&__footer {
			width: 100%;
		}

		&__overflowed-status {
			animation: download-status 2s linear infinite alternate;
		}

		&__status {
			color: #1c4775;
			text-transform: uppercase;
			font-size: 11px;
			line-height: 20px;

			&--syncing {
				animation: rotate 1s infinite;
			}

			&--offline {
				color: #ccc;
			}

			&--overflow {
				margin: 0 auto;
				overflow-x: hidden;
				position: relative;
				width: 64px;
				&::before {
					background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
					content: '';
					height: 100%;
					right: 0;
					position: absolute;
					width: 10px;
					z-index: 1;
				}
				&::after {
					background: linear-gradient(90deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
					content: '';
					height: 100%;
					left: 0;
					position: absolute;
					width: 10px;
					z-index: 1;
				}
			}
		}
	}

	// Custom ANT overrides
	& .ant-menu-item:not(.ant-menu-item-selected),
	& .ant-menu-submenu:not(.ant-menu-submenu-selected):not(.ant-menu-submenu-active):not(.ant-menu-item-selected) .ant-menu-item-icon {
		color: #cacaca;
	}

	.ant-menu-sub.ant-menu-inline {
		background: #fff;
	}

	&--vendor .ant-menu-submenu-selected {
		color: rgba(0, 0, 0, 0.85);
	}

	&--vendor .ant-menu-submenu-selected .ant-menu-item-icon {
		color: #cacaca;
	}

	&--main .ant-menu-item-selected,
	.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
		background: #fff;
	}

	& .ant-menu-vertical .ant-menu-item::after,
	.ant-menu-vertical-left .ant-menu-item::after,
	.ant-menu-vertical-right .ant-menu-item::after,
	.ant-menu-inline .ant-menu-item::after {
		border-right: 0;
	}

	& .ant-menu-vertical .ant-menu-item-selected::before,
	& .ant-menu-vertical-left .ant-menu-item-selected::before,
	& .ant-menu-vertical-right .ant-menu-item-selected::before,
	& .ant-menu-inline .ant-menu-item-selected::before {
		content: '';
		position: absolute;
		left: -8px;
		top: 0;
		bottom: 0;
		width: 12px;
		background: #27475f;
		border-radius: 12px;
	}
}

.left-menu-mobile {
	.ant-menu-sub.ant-menu-inline,
	.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
		background: #fff;
	}

	& .ant-menu-vertical .ant-menu-item-selected::before,
	.ant-menu-vertical-left .ant-menu-item-selected::before,
	.ant-menu-vertical-right .ant-menu-item-selected::before,
	.ant-menu-inline .ant-menu-item-selected::before {
		content: '';
		position: absolute;
		left: -8px;
		top: 0;
		bottom: 0;
		width: 12px;
		background: #27475f;
		border-radius: 12px;
	}

	& .ant-menu-item,
	& .ant-menu-submenu:not(.ant-menu-submenu-selected):not(.ant-menu-submenu-active) .ant-menu-item-icon {
		color: #cacaca;
	}
}
