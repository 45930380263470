.loading-placeholder {
	height: 100vh;

	&__text {
		color: #888;
	}

	&__logo {
		margin-bottom: 24px;
	}
}
