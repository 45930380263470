@import '~nprogress/nprogress'; // importing a css file from the nprogress node module
@import './variables.scss';

:root {
	background: #f8f8f8;
	background-size: cover;
}

body {
	margin: 0;
	background-color: transparent !important;
	font-family: 'Open Sans' !important;

	&:not(.ant-scrolling-effect) {
		overflow-y: visible !important;
	}
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.items-center {
	line-height: 1.1rem;
}

.ellipsis {
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}

// See https://unpkg.com/nprogress@0.2.0/nprogress.css
#nprogress {
	.bar {
		background: $color-red-primary;
	}

	.peg {
		box-shadow: 0 0 10px $color-red-primary, 0 0 5px $color-red-primary;
	}

	.spinner-icon {
		border-top-color: $color-red-primary;
		border-left-color: $color-red-primary;
	}
}

.app-loader {
	width: 100%;
	margin-top: 8rem;
	text-align: center;
}

// fixing DS Modal
reach-portal {
	[data-reach-dialog-overlay] {
		z-index: 100;
	}

	[data-reach-dialog-content] {
		width: 90vw;

		@media (min-width: 1024px) and (max-width: 1439px) {
			max-width: 768px;
		}

		@media (min-width: 1440px) {
			max-width: 1024px;
		}
	}
}

.ant-btn-link[disabled] {
	color: rgba(0, 0, 0, 0.25) !important;
}

.ant-image-preview-img {
	display: inline-block;
}

.toastNotification {
	display: flex;
	flex-direction: row;
	border-radius: 10px;
	padding: 15px 10px;
	line-height: 1;

	.ant-notification-notice-message {
		font-weight: 600;
	}

	.ant-notification-notice-icon {
		height: 65%;
		border-right: 1px solid #c0c4c8;
		padding-right: 10px;
	}

	.ant-notification-notice-close {
		position: absolute;
		top: 18px;
		right: 15px;
		font-weight: 600;
		color: #4f5b65;
	}
}
