.badge {
	border-radius: 10px;
	padding: 3px 8px;
	font-size: 10px;
	text-transform: uppercase;

	&.success {
		background-color: #007784;
		color: #fff;
	}

	&.todo {
		background-color: #ff5e3c;
		color: #fff;
	}

	&.important {
		background-color: #e02947;
		color: #fff;
	}

	&.neutral {
		background-color: #ebedee;
		color: #041624;
		border: 1px solid #dadcde;
	}
}
