nav {
	// temp workaround
	top: 0 !important;
}

.app-mf {
	display: flex;
	height: 100%;
}

.app-container {
	position: relative;
	padding: 32px;
	max-width: 1200px;
}

.page {
	min-height: calc(100vh - 12rem);
	display: flex;
	flex-direction: column;
}

.c-loader {
	text-align: center;
}

.site-layout {
	background-color: #f8f8f8;
}

.footer {
	display: flex;
	flex-direction: row;
	justify-content: center;
	position: fixed;
	bottom: 0;
	left: 0;
	right: 0;

	&__item {
		font-size: 14px;
		margin: 10px;

		&--version {
		}

		&--online {
		}
	}
}

// for TODO elements
.content-in-progress {
	min-height: 128px;
	position: relative;
	background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
	background-size: 400% 400%;
	animation: gradient 15s ease infinite;

	span {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}

	@keyframes gradient {
		0% {
			background-position: 0% 50%;
		}
		50% {
			background-position: 100% 50%;
		}
		100% {
			background-position: 0% 50%;
		}
	}
}

.anticon {
	svg {
		vertical-align: baseline;
	}
}

.custom-ant-btn {
	color: #171717;
	background-color: #eff1f2;
	border-radius: 8px;
	border: none;

	&:hover {
		color: #171717;
		background-color: #eff1f2;
		box-shadow: 0 8px 10px -5px rgb(0 0 0 / 20%);
	}

	&:disabled {
		color: #f2f4f5 !important;
		background-color: #637a8c !important;
	}

	&.ant-btn-primary {
		background-color: #f8bb2d;
		border-color: #f8bb2d;

		&:hover {
			background-color: #f8bb2d;
			border-color: #f8bb2d;
		}
	}
}

.form-item--horizontal {
	display: flex;
	flex-direction: row !important;
	.ant-form-item-label {
		text-align: left;
		padding-right: 1em;
		padding-bottom: 2px;
	}
}

.ant-table--transparent {
	margin-bottom: 16px;

	.ant-table {
		background: transparent;
	}

	.ant-table-thead {
		th {
			color: #888787;
			font-weight: 600;
			font-size: 13px;
		}
	}
}

@mixin fullscreen-modal {
	// https://allthingssmitty.com/2020/05/11/css-fix-for-100vh-in-mobile-webkit/
	&.ant-modal {
		position: relative;
		height: -webkit-fill-available !important;
		height: 100vh;
		width: 100vw !important;
		margin: 0;
		top: 0;
		padding: 0;

		.ant-modal-header {
			padding: 8px 16px;
		}

		.ant-modal-content {
			position: absolute;
			height: -webkit-fill-available !important;
			height: 100vh;
			width: 100vw;
			margin: 0;
			top: 0;

			.ant-modal-close-x {
				height: 40px;
				line-height: 40px;
			}
		}

		.ant-modal-body {
			position: absolute;
			top: 55px;
			bottom: 53px;
			left: 0;
			right: 0;
			margin: 0 auto;
			width: 100%;
			padding: 0 15%;
			overflow-y: auto;
		}

		&.floating {
			.ant-modal-footer {
				padding: 0;
			}
		}
	}
}

.fullscreen-modal-force {
	@include fullscreen-modal();
}

@media (max-width: 1200px) {
	.fullscreen-modal {
		@include fullscreen-modal();
	}
}

.ant-table--nested {
	.ant-table-thead {
		th {
			color: #888787;
			font-weight: 600;
			font-size: 13px;
		}
	}
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled),
.ant-transfer-list-content-item-checked,
.ant-transfer-list-content-item:not(.ant-transfer-list-content-item-disabled).ant-transfer-list-content-item-checked:hover {
	background-color: #f1f1f1;
}

@keyframes rotate {
	100% {
		transform: rotate(180deg);
	}
}

// Border gets attached on :focus state - but it's not in the default state
// That caused the button to jump when it's focused
// for details see: https://ah4r.atlassian.net/browse/FSME-954
button[class$="btn-primary"],
.ant-btn.ant-btn-primary {
	border: 1px solid transparent;
}


