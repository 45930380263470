.map {
    height: 100%;

    .pin {
        width: max-content;
        transform-origin: center bottom;
        transform: translate(-50%, -100%) scale(1);

        &--active {
            transform: translate(-50%, -100%) scale(1.5);
        }
    }
}
