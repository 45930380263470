@import '@4r/ant-theme-config/dist/lib/colors.scss';

.work-order-list {
	&__cii-popover {
		.ant-popover-inner {
			border-radius: 4px;

			.ant-popover-inner-content {
				padding: 8px 0;
			}
		}
		&.ant-popover-placement-topRight .ant-popover-arrow {
			right: 8px;
		}

		&.ant-popover-placement-topLeft .ant-popover-arrow {
			left: 8px;
		}
	}
	&__popover-content {
		padding: 0 16px;
	}
}
