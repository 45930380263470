.work-order-page {
	&__tabs {
		height: 100%;

		& .ant-tabs-content {
			height: 100%;
		}
	}
}

.inventory {
	&__cell {
		border-left: 1px solid #ccc;
		border-right: 1px solid #ccc;
	}

	&__table {
		table {
			width: 100% !important;
		}
	}
}

.order-history {
	&__stat {
		cursor: pointer;
		padding: 24px;
		background: #ebedee;
		border-radius: 4px;
		margin: 12px 0;

		&__text {
			text-align: center;
		}

		&__title {
			font-size: 16px;
			text-transform: uppercase;
			font-weight: 600;
			margin-top: 12px;
		}

		&__circle {
			font-size: 32px;
			color: #fff;
			width: 112px;
			height: 112px;
			background: #1c4775;
			border-radius: 100%;
			position: relative;

			&__sub {
				font-size: 16px;
				color: #1c4775;
				border: 4px solid #ebedee;
				border-radius: 100%;
				background: #fff;
				width: 62px;
				height: 62px;
				position: absolute !important;
				top: 0;
				right: -32px;
			}

			span {
				font-size: 13px;
			}
		}
	}
}
