.offline-message {
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;

	&__content {
		display: flex;
		flex-direction: column;
		height: 100%;
		justify-content: space-between;
	}

	&__icon {
		margin-bottom: 12px;

		svg {
			width: 112px;
			height: 112px;
		}
	}

	&__title {
		font-size: 18px;
		font-weight: 600;
		margin: 12px 0;
		margin-top: 24px;
	}

	&__link {
		font-weight: 600;
		border: 1px solid;
	}

	&__footer {
		margin-bottom: 12px;
	}
}
