.status-page {
	&__card {
		&__column {
			padding: 24px;

			&--bordered {
				border-right: 1px solid #ededed;
			}
		}

		.ant-card-body {
			padding: 0;
		}
	}

	&__progress {
		margin: 64px 0;
		&__title {
			text-transform: capitalize;
			font-size: 16px;
			font-weight: 600;
		}
	}

	&__icon {
		border: 2px solid #ccc;
		width: 12px;
		height: 12px;
		border-radius: 100%;

		&--syncing {
			border: 2px solid #1890ff;
		}
	}
}
