@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i,800,800i&display=swap');
@import url('https://use.typekit.net/jzi4rxj.css');

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
	html {
		font-family: 'Open Sans';
	}
}
