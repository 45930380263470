@import '@4r/ant-theme-config/dist/lib/colors.scss';

.extras {
	padding-left: 8px;
}

.more {
	color: white;
	font-weight: 600;
}

.priority {
	background: white;
}

.centered-content {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.link {
	align-items: center;
	border-top: 1px solid $color-grey-80;
	display: flex;
	justify-content: center;
	padding: 16px 0 8px;
}

.link-content {
	display: flex;
	color: $color-grey-08;
	column-gap: 8px;
	&:hover {
		color: $color-grey-00;
	}
}
