.mf-lifecycle {
	overflow: visible !important;

	.ant-spin-nested-loading,
	.ant-spin-container {
		display: flex;
		flex-direction: column;
		height: 100%;
		overflow: visible;
	}

	.ant-tabs {
		height: 100%;
	}

	.ant-tabs-content-holder {
		overflow: auto;
		height: calc(100vh - 200px);
	}
}
