@import '@4r/ant-theme-config/dist/lib/colors.scss';

.orders-page {
	display: flex;
	flex-direction: column;

	.items-center {
		min-height: 4.5rem;
	}

	button[role='switch'] {
		background-image: linear-gradient(to right, rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.25)), linear-gradient(to right, #fff, #fff);
		&.ant-switch-checked {
			background: $color-blue-primary;
		}
	}

	.ant-tabs-nav {
		border-bottom: none;
		&::before {
			border-bottom-color: $color-grey-10;
		}

		.ant-tabs-ink-bar {
			background-color: $color-blue-primary;
		}

		.ant-tabs-tab {
			color: $color-grey-100;
			&.ant-tabs-tab-active .ant-tabs-tab-btn {
				color: $color-grey-100;
			}
		}
	}

	.map-container {
		border: 1px solid $color-grey-10;
		border-radius: 4px 4px 0 0;
	}

	.property-search-input {
		border-color: $color-grey-10;
		border-radius: 4px;

		.ant-input {
			height: 30px;
			font-size: 14px;

			&::placeholder {
				font-size: 14px;
				color: $color-grey-60;
			}
		}
	}

	.order-list-mobile {
		display: flex;
		flex-direction: column;

		&__card {
			border-color: $color-grey-10;
			border-radius: 4px;
		}

		&__issues-mobile {
			order: 2;
		}

		&__schedule-mobile {
			width: 100%;
		}
	}
}
